import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const LeahAlexxanderrCaine = () => (
  <Layout>
    <SEO
      title="Natural Hair with Model Leah Alexxanderr-Caine"
      description="This week we had a chat with the amazing Leah Alexxanderr-Caine. She is a model born & raised in east London, currently living in Essex."
    />
    <h1>Talking Natural Hair with Leah Alexxanderr-Caine</h1>
    <h2 style={{color:"Orchid"}}>A quick chat with Leah and her how she maintains her natural hair</h2>

    <p>This week we had a chat with the amazing Leah Alexxanderr-Caine. She is a model born & raised in East London, currently living in Essex. Follow Leah on Instagram <a
        href="https://www.instagram.com/lee.estelle_/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @lee.estelle_
      </a></p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/UaU0kPK.jpg"
        alt="Leah Alexxanderr-Caine nahc red"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Leah Alexxanderr-Caine</p>
    </div>

    <h3>What does your natural hair mean to you?</h3>
    <p>Natural hair is very important to me because I use it as a form of expression.</p>

    <h3>How would you describe your hair?</h3>
    <p>My natural hair is a mixture of tight curly coils & some parts are a more loose curl, not 100% sure what the type is.</p>

    <h3>Talk us through your hair routine</h3>
    <p>During the week if I’m working a lot I wear a wig to protect my hair so my hair will be in cornrows but when I have my hair out I always wet it before styling & then apply some kind of curl cream & coconut or jojoba oil, also I always do my edges with eco styler gel.</p>

      <h3>How did you view your hair growing up and were other people interested in it?</h3>
      <p>I always loved my hair growing up as my mom was a hairdresser so I was always taught to embrace it & I would do loads of different styles. I have 2 younger sisters that I would practice on also so I learnt to braid to a good level at about 9/10 years old.</p>

      <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/sEKzwBR.jpg"
        alt="Leah Alexxanderr-Caine sunlit in a coat. Natural afro hair care"
        style={{marginBottom: "5px"}}
      />
    </div>

    <p>Growing up especially during secondary school I used to wear my hair in a blown out Afro & my hair was quite long so people were always intrigued and wanted to touch it, which was extremely annoying lool</p>

    <h3>What advice would you give for taking care of natural hair?</h3>
    <p>Advice I’d give someone for taking care of natural hair is to not do too much, find out what routine works for you & stick to it. Also if you’re watching tutorials on YouTube to always find someone with a similar curl pattern/hair texture to yours as it’s gonna be easier for you to follow.</p>

    <h3>What is your favourite hairstyle right now?</h3>
    <p>My favourite hairstyle at the moment is a high puff with baby hairs, sometimes I braid the front just to make it a little different but it’s so easy & looks cute for loads of different occasions.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/Vq1HpiX.jpg"
        alt="Leah Alexxanderr-Caine high puff baby hair. Natural afro hair care"
        style={{marginBottom: "5px"}}
      />
    </div>

    <h3>What are you passionate about?</h3>
    <p>At the moment I’ve just started painting again after about a years break & art in general is something I’ve always been passionate about so it’s good to get back into it.</p>

    <h3>How can we follow/ contact you?</h3>
    <p>You can follow me <a
        href="https://www.instagram.com/lee.estelle_/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @lee.estelle_
      </a> on Instagram x</p>




    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}




      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/talking-with-blessed-shaba/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Going Natural With Blessed
            </Link>{" "}
            - Zambian born Blessed talks about her decision to go natural. We discuss her top hairstyles and which products are best for natural hair...
          </p>
          <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>          
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default LeahAlexxanderrCaine
